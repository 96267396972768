import React from "react";
import { Link } from "react-router-dom";
import './SiteFooter.css'
function SiteFooter() {
  return (
    <div className="site-footer">
      <div className="first-sec">
        <b>
          {" "}
          <Link to="/privacypolicy">Privacy & Cookies Policy </Link>|{" "}
          <Link to="/termsandconditions"> Web Terms of Use</Link>| Accessibility
        </b>
      </div>
      <div className="second-sec">
        <i>
          2022-2024 Life by Petto Inc. All Rights Reserved. Life by Petto is a
          registered trademark of Life by Petto Inc. and is registered with the
          Philippines Patent & Trademark Office
        </i>
      </div>
    </div>
  );
}

export default SiteFooter;
