import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import dog from "../../assets/images/dog.png";
import cat from "../../assets/images/cat.png";
import reviewOne from "../../assets/images/review1.png";
import reviewTwo from "../../assets/images/review2.png";
import reviewThree from "../../assets/images/review.png";
import reviewFour from "../../assets/images/review4.png";
import "./Review.css";

function Review() {
  // Sample review data
  const reviews = [
    {
      id: 1,
      image: reviewOne,
      review:
        "We hope Life by Petto becomes your partner fur life throughout your pet’s well-lived life.!",
      author: "John S",
    },
    {
      id: 2,
      image: reviewTwo,
      review:
        "We hope Life by Petto becomes your partner fur life throughout your pet’s well-lived life",
      author: "Sasha M",
    },
    {
      id: 3,
      image: reviewThree,
      review:
        "We hope Life by Petto becomes your partner fur life throughout your pet’s well-lived life",
      author: "Trish Y.",
    },
    {
      id: 4,
      image: reviewFour,
      review:
        "We hope Life by Petto becomes your partner fur life throughout your pet’s well-lived life.",
      author: "Daniel R.",
    },
  ];

  return (
    <div>
      <div className="review-header text-center" >
        <img src={cat} alt="Cat" className="img-fluid mx-2 cat" />
        <h2>FOR PET PARENTS BY PET PARENTS</h2>
        <img src={dog} alt="Dog" className="img-fluid mx-2 dog" />
      </div>
      <Container>
        <Row className="reviews">
          {reviews.map((review) => (
            <Col xs={12} sm={4} md={3} key={review.id} className="mb-4">
              <Card className="h-100 review-card">
                <Card.Img
                  variant="top"
                  src={review.image}
                  className="review-image"
                />
                <p className="review-body">{review.review}</p>
                <b className="review-author">-{review.author}.</b>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Review;
