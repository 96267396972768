import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as GuideOne } from "../../assets/images/guide-one.svg";
import { ReactComponent as GuideTwo } from "../../assets/images/guide-two.svg";
import { ReactComponent as GuideThree } from "../../assets/images/guide-three.svg";
import "./GuideUs.css";
function GuideUs() {
  return (
    <Container>
      <Row className="guide-us">
        <div className="guide-header">
          <h3>WHAT GUIDES US?</h3>
        </div>

        <Col xs={12} md={4}>
          <GuideOne className="guide-1"/>
          <div className="guide-section">
            <h4>Responsible Pet Ownership</h4>
            <p>
              This is what guides us. We know that having a pet is a lifetime
              commitment but sometimes that doesn’t come easy. <br />
              <br /> We hope Life by Petto becomes your partner fur life
              throughout your pet’s well-lived life.
            </p>
          </div>
        </Col>
        <Col xs={12} md={4} >
          <GuideTwo  className="guide-2"/>
          <div className="guide-section">
            <h4>For Pet Parents, By Pet Parents</h4>
            <p>
              Life by Petto was created with pet parents in mind because we’re
              pet parents ourselves. <br />
              <br /> Every aspect of our service has been considered with our
              fur babies in mind.
            </p>
          </div>
        </Col>
        <Col xs={12} md={4} >
          <GuideThree className="guide-3" />
          <div className="guide-section">
            <h4 >We All Win When We Pitch In</h4>
            <p>
              We work with communities and partners that allow us to help and
              support animal welfare organisations around the country. <br />
              <br />
              Every Life by Petto member by default is helping these
              organisations when you avail of the service.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default GuideUs;
