import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/images/logo-petto.png";
import {
  RiCloseCircleLine,
  RiMenu3Line,
  RiArrowDropRightLine,
} from "react-icons/ri";

function Navbar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const isActiveLink = (path) => location.pathname === path;

  return (
    <div className="navbar">
      <div className="menu">
        <img src={logo} alt="petto logo" className="menuicon" />
        <button className="menubar" onClick={toggleMenu}>
          <RiMenu3Line />
        </button>
      </div>
      <ul className={isActive ? "active" : ""}>
        <li className="menu-close" onClick={toggleMenu}>
          {isActive && isMobile ? (
            <RiCloseCircleLine style={{ fontSize: "29px" }} />
          ) : (
            ""
          )}
        </li>
        <li className={isActiveLink("/plans") ? "active-link" : ""}>
          <Link to="/plans">Plans</Link>
        </li>
        <li className={isActiveLink("/partners") ? "active-link" : ""}>
          <Link to="/partners">Partners</Link>
        </li>
        <li className={isActiveLink("/") ? "active-link" : ""}>
          <Link to="/">
            {isActive ? "Home" : <img src={logo} alt="petto logo" />}
          </Link>
        </li>
        <li className={isActiveLink("/support") ? "active-link" : ""}>
          <Link to="/support">Support</Link>
        </li>
        <li className={isActiveLink("/about") ? "active-link" : ""}>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="https://app.lifebypetto.com">
            <button>
              Get the app now <RiArrowDropRightLine />
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
