import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import vetDetials from "./Vet-Detials";
import "./VetinaryClinics.css";
import { RiArrowLeftWideFill, RiArrowRightWideFill } from "react-icons/ri";

function VetinaryClinics() {
  const [show, setShow] = useState(false);
  const logosRef = useRef(null);

  const handleShow = () => {
    setShow(!show);
  };

  const scrollLeft = () => {
    if (logosRef.current) {
      logosRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (logosRef.current) {
      logosRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div>
      <Container>
        <Row className="vetinary-clinics">
          <Col md={7}></Col>
          <Col md={4} className="vetinary-header">
            <h1>Veterinary Clinics</h1>
            <i
              style={{ color: "white", cursor: "pointer" }}
              onClick={handleShow}
            >
              see {show ? "less" : "more"}
            </i>
          </Col>
        </Row>
        {show && (
          <div className="vet-detials">
            <p>
              We partnered with the vets you trust for your pet’s health. We
              will continuously expand our relationship with our partners to
              provide your furbaby with the best care.
            </p>
            <div className="scroll-container" style={{width:"100%", display:"flex" ,justifyContent:"space-around"}}>
              <button className="scroll-button left" onClick={scrollLeft}>
                <RiArrowLeftWideFill />
              </button>
              <div className="vet-logos" ref={logosRef}>
                {vetDetials.map((logos) => (
                  <div key={logos.key}>
                    <img src={logos.logo} alt="" />
                  </div>
                ))}
              </div>
              <button className="scroll-button right" onClick={scrollRight}>
                <RiArrowRightWideFill />
              </button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default VetinaryClinics;
