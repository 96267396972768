import React, { useState } from "react";
import "./ContactModal.css";
import { RiCloseCircleLine } from "react-icons/ri";
import emailjs from 'emailjs-com';

function ContactModal({ show, setShow }) {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    console.log("hiiii");
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      company: formData.company,
      phone: formData.phone,
      email: formData.email,
      reply_to:formData.email
    };
    console.log(templateParams);

    emailjs.send('service_r4srnjj', 'template_bxewk5m', templateParams, 'mMC-Erp8Lsxr63wBm')
      .then((result) => {
        console.log("sendsuccess fully");
        console.log(result.text);
        setShow(false);
      }, (error) => {
        console.log("somethign fishy");
        console.log(error.text);
      });
  };

  return (
    <div className={`contact-modal ${show ? 'show' : ''}`}>
      <div className="contact">
        <div className="close-btn">
          <RiCloseCircleLine fontSize={40} onClick={() => setShow(false)} />
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <h2>Let's talk business</h2>
            <p>We’re ready to hear your ideas and work together as a team for our beloved furbabies!</p>
            <div className="form-group">
              <label htmlFor="name" style={{ fontWeight: 700 }}>Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="company" style={{ fontWeight: 700 }}>Company:</label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Your Company"
                value={formData.company}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone" style={{ fontWeight: 700 }}>Phone:</label>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Your Phone Number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ fontWeight: 700 }}>Email Address:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email Address"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
          <div className="form-image">
            <h3>
              Are you the owner of a pet business?
              <br />
              <br />
              Come pawtner with us!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactModal;
