// src/assets/data/vetlogos.js

import AnimalHouse from "../../assets/images/vet-logos/AnimalHouse.png";
import AnimalWellness from "../../assets/images/vet-logos/AnimalWellness.png";
import Baivet from "../../assets/images/vet-logos/Baivet.png";
import CatHospital from "../../assets/images/vet-logos/CatHospital.png";
import DocFreds from "../../assets/images/vet-logos/DocFerds.png";
import HayopKalinga from "../../assets/images/vet-logos/HayopKalinga.png";
import KingsRoad from "../../assets/images/vet-logos/kingsRoad.png";
import ManilaFeline from "../../assets/images/vet-logos/ManilaFeline.png";
import MountSinai from "../../assets/images/vet-logos/MountSinai.png";
import NewAlabang from "../../assets/images/vet-logos/NewAlabang.png";
import PetExpress from "../../assets/images/vet-logos/PetExpress.png";
import Petropolis from "../../assets/images/vet-logos/Petropolis.png";
import PPBCC from "../../assets/images/vet-logos/PPBCC.png";

const vetDetials = [
  { key: 1, logo: AnimalHouse, name: "Animal House" },
  { key: 2, logo: AnimalWellness, name: "Animal Wellness" },
  { key: 3, logo: Baivet, name: "Baivet" },
  { key: 4, logo: CatHospital, name: "Cat Hospital" },
  { key: 5, logo: DocFreds, name: "Doc Freds" },
  { key: 6, logo: HayopKalinga, name: "Hayop Kalinga" },
  { key: 7, logo: KingsRoad, name: "Kings Road" },
  { key: 8, logo: ManilaFeline, name: "Manila Feline" },
  { key: 9, logo: MountSinai, name: "Mount Sinai" },
  { key: 10, logo: NewAlabang, name: "New Alabang" },
  { key: 11, logo: PetExpress, name: "Pet Express" },
  { key: 12, logo: Petropolis, name: "Petropolis" },
  { key: 13, logo: PPBCC, name: "PPBCC" },
];

export default vetDetials;
