import React from "react";
import "./Featured.css";
import { Col, Container, Row } from "react-bootstrap";
import articleImageOne from "../../assets/images/articleImage1.jpg";
import articleImageTwo from "../../assets/images/articleImage2.jpg";

function Featured() {
  return (
    <Container>
      <Row className="featured-videos">
        <Col xs={12} md={8}>
          <p style={{ fontSize: "22px", textAlign: "left", fontWeight: 700 }}>
            Featured Videos
          </p>
          <iframe
            className="main-video"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/BfhARF55-sY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <Row className="featured-sub-videos">
            <Col xs={6} md={4}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/oRDRfikj2z8?si=nIDjw0hVXFSTdVla"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Col>
            <Col xs={6} md={4}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/VYBN33JBUyA?si=aIMHxR_RN0dB1S4h"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Col>
            <Col xs={6} md={4}>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/1lgXNaLMqjI?si=J19IoaKWbXxecL9L"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4}>
          <p
            style={{ fontWeight: 600, textAlign: "left", marginBottom: "10px" }}
          >
            Featured Articles
          </p>
          <Row className="featured-articles">
            <Col xs={12} className="article-card">
              <img
                src={articleImageOne}
                alt="Article One"
                className="article-images"
              />
              <b>The Benefits of Pet Health Plans</b>
              <p>
                One of the primary advantages of Life by Petto's health plans is
                the ability to provide proactive and... See More
              </p>
            </Col>
            <Col xs={12} className="article-card">
              <img
                src={articleImageTwo}
                alt="Article Two"
                className="article-images"
              />
              <b>The Benefits of Pet Health Plans</b>
              <p>
                One of the primary advantages of Life by Petto's health plans is
                the ability to provide proactive and... See More
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Featured;
