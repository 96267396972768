import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import VetinaryClinics from "../Components/VetinaryClinics/VetinaryClinics";
import GroomingServices from "../Components/GroomingServices/GroomingServices";
import ForHumans from "../Components/ForHumans/ForHumans";
import AnimalWelfare from "../Components/AnimalWelfare/AnimalWelfare";
import PetSupplies from "../Components/PetSupplies/PetSupplies";
import ContactModal from "../Modal/ContactModal";
import SiteFooter from "../Components/SiteFooter/SiteFooter";

function PartnerPage() {
  return (
    <div>
      <Navbar />
      <VetinaryClinics />
      <GroomingServices />
      <PetSupplies />

      <AnimalWelfare />
      <ForHumans />
      <Footer />
      <SiteFooter />
    </div>
  );
}

export default PartnerPage;
