import React from "react";
import "./AnimalWelfare.css";
import { Col, Container, Row } from "react-bootstrap";

function AnimalWelfare() {
  return (
    <Container>
      <Row className="animal-welfare">
        <Col md={6} className="welfare-header">
          <h1>
            Animal <br></br> Welfare Groups
          </h1>
          <i style={{ color: "white", textAlign: "center", cursor: "pointer" }}>
            Coming Soon
          </i>
        </Col>
        <Col md={6}></Col>
      </Row>
    </Container>
  );
}

export default AnimalWelfare;
