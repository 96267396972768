import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logofooter from "../../assets/images/logo-petto-footer.png";
import PettoAppLogo from "../../assets/images/petto-app-log.svg"; // Updated import
import playStoreLogo from "../../assets/images/play-store.png";
import appStoreLogo from "../../assets/images/app-store.png";
import pettoDailyLogo from "../../assets/images/petto-daily-logo.svg";
import { RiMailAddFill } from "react-icons/ri";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="footer">
        <Container style={{width:"90%"}}>
          <Row className="footer-row">
            <Col xs={12} md={3} className="footer-col">
              <ul>
                <li className="logo-section">
                  <img src={logofooter} alt="petto-logo" />
                </li>
                <li>Follow Us</li>
                <li>
                  <RiMailAddFill style={{ color: "#FFF883" }} />
                  hello@lifebypetto.com
                </li>
              </ul>
            </Col>
            <Col xs={12} md={3} className="footer-col">
              <table style={{ textAlign: "left", width: "100%" }}>
                <tr>
                  <th>
                    {" "}
                    <Link
                      to="/about"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      About Us
                    </Link>
                  </th>
                  <th>
                    <Link
                      to="/support"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      Support
                    </Link>
                  </th>
                </tr>
                <tr style={{ fontSize: "13px" }}>
                  <td>
                    <li>
                      <Link
                        to="/privacypolicy"
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        {" "}
                        Privacy Policy{" "}
                      </Link>{" "}
                    </li>
                  </td>
                  <td>
                    <li>
                      <Link
                        to="/termsandconditions"
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        {" "}
                        Terms and Conditions{" "}
                      </Link>{" "}
                    </li>
                  </td>
                </tr>
              </table>
            </Col>
            <Col xs={12} md={3} className="footer-col links-section">
              <ul>
                <li>
                  <h5>Life by Petto App</h5>
                </li>
                <li>
                  <p className="app-def">
                    Download today and experience the Life by Petto difference
                  </p>
                </li>
                <li>
                  <div className="footer-logos">
                    <div className="app-logos">
                      <img src={PettoAppLogo} alt="petto-log" srcset="" />
                    </div>
                    <div className="play-store">
                      <Link to="https://play.google.com/store/apps/details?id=com.lifebypetto.twa">
                        <img src={playStoreLogo} alt="" srcset="" />
                      </Link>
                      <Link to="https://apps.apple.com/in/app/life-by-petto/id1586568912">
                        <img src={appStoreLogo} alt="" srcset="" />
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col xs={12} md={3} className="footer-col">
              <ul>
                <li>
                  <h6>Petto Daily</h6>
                </li>
                <li>
                  <Link
                    to="https://www.pettodaily.com"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    www.pettodaily.com
                  </Link>
                </li>
                <li>
                  <img
                    src={pettoDailyLogo}
                    alt=""
                    srcset=""
                    style={{ width: "100px" }}
                  />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
