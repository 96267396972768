import React from "react";
import "./Terms.css";
function TermsConditions() {
  return (
    <div className="terms-conditons">
      <h1>Terms & Conditions</h1>
      <p>Updated at 2024-01-01</p>
      <dl style={{ marginTop: "20px" }}>
        <dt>General Terms</dt>
        <dd>
          By accessing and placing an order with Life by Petto, you confirm that
          you are in agreement with and bound by the terms of service contained
          in the Terms & Conditions outlined below. These terms apply to the
          entire website and any email or other type of communication between
          you and Life by Petto.
        </dd>

        <dd>
          Under no circumstances shall Life by Petto team be liable for any
          direct, indirect, special, incidental or consequential damages,
          including, but not limited to, loss of data or profit, arising out of
          the use, or the inability to use, the materials on this site, even if
          the Life by Petto team or an authorised representative has been
          advised of the possibility of such damages. If your use of materials
          from this site results in the need for servicing, repair or correction
          of equipment or data, you assume any costs thereof.
        </dd>

        <dd>
          Life by Petto will not be responsible for any outcome that may occur
          during the course of usage of our resources. We reserve the rights to
          change prices and revise the resources usage policy at any moment.
        </dd>
      </dl>
      <dl>
        <dt>Licence</dt>
        <dd>
          Life by Petto grants you a revocable, non-exclusive, non-transferable,
          limited licence to download, install and use the app strictly in
          accordance with the terms of this Agreement.
        </dd>
        <dd>
          These Terms & Conditions are a contract between you and Life by Petto
          (referred to in these Terms & Conditions as "Life by Petto", "us",
          "we" or "our"), the provider of the Life by Petto website and the
          services accessible from the Life by Petto website (which are
          collectively referred to in these Terms & Conditions as the "Life by
          Petto Service").
        </dd>
        <dd>
          You are agreeing to be bound by these Terms & Conditions. If you do
          not agree to these Terms & Conditions, please do not use the Life by
          Petto Service. In these Terms & Conditions, "you" refers both to you
          as an individual and to the entity you represent. If you violate any
          of these Terms & Conditions, we reserve the right to cancel your
          account or block access to your account without notice.
        </dd>
      </dl>
      <dl>
        <dt>Definitions and key terms</dt>
        <dd>
          To help explain things as clearly as possible in this Terms &
          Conditions, every time any of these terms are referenced, are strictly
          defined as:
        </dd>
        <ul>
          <li>
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, and remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: when this policy mentions “Company,” “we,” “us,” or “our,”
            it refers to Life by Petto Pte Ltd, 410 North Bridge Road City Hall
            Building, Singapore 188726 that is responsible for your information
            under these Terms & Conditions.
          </li>
          <li>
            Country: where Life by Petto or the owners/founders of Life by Petto
            are based, in this case is Singapore and Philippines
          </li>
          <li>
            Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit Life by Petto
            and use the services.
          </li>
          <li>
            Service: refers to the service provided by Life by Petto as
            described in the relative terms (if available) and on this platform
          </li>
          <li>
            Third-party service: refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </li>
          <li>
            Website: Life by Petto’’s site, which can be accessed via this URL:
            <a href="https://www.lifebypetto.com/">
              https://www.lifebypetto.com/
            </a>
          </li>
          You: a person or entity that is registered with Life by Petto to use
          the Services.
        </ul>
      </dl>
      <dl>
        <dt>Restrictions</dt>
        <dd>You agree not to, and you will not permit others to:</dd>
        <ul>
          <li>
            Licence, sell, rent, lease, assign, distribute, transmit, host,
            outsource, disclose or otherwise commercially exploit the app or
            make the platform available to any third party.
          </li>
          <li>
            Modify, make derivative works of, disassemble, decrypt, reverse
            compile or reverse engineer any part of the app.
          </li>
          <li>
            Remove, alter or obscure any proprietary notice (including any
            notice of copyright or trademark) of Life by Petto or its
            affiliates, partners, suppliers or the licensors of the app.
          </li>
        </ul>
      </dl>
      <dl>
        <dt>Return and Refund Policy</dt>
        <dd>
          Thanks for shopping at Life by Petto. We appreciate the fact that you
          like to buy the stuff we build. We also want to make sure you have a
          rewarding experience while you’re exploring, evaluating, and
          purchasing our products.
        </dd>
        <dd>
          As with any shopping experience, there are terms and conditions that
          apply to transactions at Life by Petto. We’ll be as brief as our
          attorneys will allow. The main thing to remember is that by placing an
          order or making a purchase at Life by Petto, you agree to the terms
          along with Life by Petto’s Privacy Policy.
        </dd>
        <dd>
          If, for any reason, You are not completely satisfied with any good or
          service that we provide, don't hesitate to contact us and we will
          discuss any of the issues you are going through with our product.
        </dd>
      </dl>
      <dl>
        <dt>Your Suggestions</dt>
        <dd>
          Any feedback, comments, ideas, improvements or suggestions
          (collectively, "Suggestions") provided by you to Life by Petto with
          respect to the app shall remain the sole and exclusive property of
          Life by Petto.
        </dd>
        <dd>
          Life by Petto shall be free to use, copy, modify, publish, or
          redistribute the Suggestions for any purpose and in any way without
          any credit or any compensation to you.
        </dd>
      </dl>
      <dl>
        <dt>Your Consent</dt>
        <dd>
          We've updated our Terms & Conditions to provide you with complete
          transparency into what is being set when you visit our site and how
          it's being used. By using our app, registering an account, or making a
          purchase, you hereby consent to our Terms & Conditions.
        </dd>
      </dl>
      <dl>
        <dt>Links to Other Websites</dt>
        <dd>
          The Terms & Conditions apply only to the Services. The Services may
          contain links to other websites not operated or controlled by Life by
          Petto. We are not responsible for the content, accuracy or opinions
          expressed in such websites, and such websites are not investigated,
          monitored or checked for accuracy or completeness by us. Please
          remember that when you use a link to go from the Services to another
          website, our Terms & Conditions are no longer in effect. Your browsing
          and interaction on any other website, including those that have a link
          on our platform, is subject to that website’s own rules and policies.
          Such third parties may use their own cookies or other methods to
          collect information about you.
        </dd>
      </dl>
      <dl>
        <dt>Cookies</dt>
        <dd>
          Life by Petto uses "Cookies" to identify the areas of our website that
          you have visited. A Cookie is a small piece of data stored on your
          computer or mobile device by your web browser. We use Cookies to
          enhance the performance and functionality of our app but are
          non-essential to their use. However, without these cookies, certain
          functionality like videos may become unavailable or you would be
          required to enter your login details every time you visit the app as
          we would not be able to remember that you had logged in previously.
          Most web browsers can be set to disable the use of Cookies. However,
          if you disable Cookies, you may not be able to access functionality on
          our website correctly or at all. We never place Personally
          Identifiable Information in Cookies.
        </dd>
      </dl>
      <dl>
        <dt>Changes To Our Terms & Conditions</dt>
        <dd>
          You acknowledge and agree that Life by Petto may stop (permanently or
          temporarily) providing the Service (or any features within the
          Service) to you or to users generally at Life by Petto’s sole
          discretion, without prior notice to you. You may stop using the
          Service at any time. You do not need to specifically inform Life by
          Petto when you stop using the Service. You acknowledge and agree that
          if Life by Petto disables access to your account, you may be prevented
          from accessing the Service, your account details or any files or other
          materials which are contained in your account.
        </dd>
        <dd>
          If we decide to change our Terms & Conditions, we will post those
          changes on this page, and/or update the Terms & Conditions
          modification date below.
        </dd>
      </dl>
      <dl>
        <dt>Modifications to Our app</dt>
        <dd>
          Life by Petto reserves the right to modify, suspend or discontinue,
          temporarily or permanently, the app or any service to which it
          connects, with or without notice and without liability to you.
        </dd>
      </dl>
      <dl>
        <dt>Updates to Our app</dt>
        <dd>
          Life by Petto may from time to time provide enhancements or
          improvements to the features/ functionality of the app, which may
          include patches, bug fixes, updates, upgrades and other modifications
          ("Updates").
        </dd>
        <dd>
          Updates may modify or delete certain features and/or functionalities
          of the app. You agree that Life by Petto has no obligation to (i)
          provide any Updates, or (ii) continue to provide or enable any
          particular features and/or functionalities of the app to you. You
          further agree that all Updates will be (i) deemed to constitute an
          integral part of the app, and (ii) subject to the terms and conditions
          of this Agreement.
        </dd>
      </dl>
      <dl>
        <dt>Third-Party Services</dt>
        <dd>
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          ("Third- Party Services").
        </dd>
        <dd>
          You acknowledge and agree that Life by Petto shall not be responsible
          for any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. Life by Petto does not assume and shall
          not have any liability or responsibility to you or any other person or
          entity for any Third-Party Services.
        </dd>
        <dd>
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </dd>
      </dl>
      <dl>
        <dt>Term and Termination</dt>
        <dd>
          This Agreement shall remain in effect until terminated by you or Life
          by Petto. Life by Petto may, in its sole discretion, at any time and
          for any or no reason, suspend or terminate this Agreement with or
          without prior notice
        </dd>
        <dd>
          This Agreement will terminate immediately, without prior notice from
          Life by Petto, in the event that you fail to comply with any provision
          of this Agreement. You may also terminate this Agreement by deleting
          the app and all copies thereof from your computer. Upon termination of
          this Agreement, you shall cease all use of the app and delete all
          copies of the app from your computer.
        </dd>
        <dd>
          Termination of this Agreement will not limit any of Life by Petto's
          rights or remedies at law or in equity in case of breach by you
          (during the term of this Agreement) of any of your obligations under
          the present Agreement.
        </dd>
      </dl>
      <dl>
        <dt>Copyright Infringement Notice</dt>
        <dd>
          If you are a copyright owner or such owner’s agent and believe any
          material on our app constitutes an infringement on your copyright,
          please contact us setting forth the following information: (a) a
          physical or electronic signature of the copyright owner or a person
          authorised to act on his behalf; (b) identification of the material
          that is claimed to be infringing; (c) your contact information,
          including your address, telephone number, and an email; (d) a
          statement by you that you have a good faith belief that use of the
          material is not authorised by the copyright owners; and (e) the a
          statement that the information in the notification is accurate, and,
          under penalty of perjury you are authorised to act on behalf of the
          owner.
        </dd>
      </dl>
      <dl>
        <dt>Indemnification</dt>
        <dd>
          You agree to indemnify and hold Life by Petto and its parents,
          subsidiaries, affiliates, officers, employees, agents, partners and
          licensors (if any) harmless from any claim or demand, including
          reasonable attorneys' fees, due to or arising out of your: (a) use of
          the app; (b) violation of this Agreement or any law or regulation; or
          (c) violation of any right of a third party.
        </dd>
      </dl>
      <dl>
        <dt>No Warranties</dt>
        <dd>
          The app is provided to you "AS IS" and "AS AVAILABLE" and with all
          faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, Life by Petto, on its own behalf and
          on behalf of its affiliates and its and their respective licensors and
          service providers, expressly disclaims all warranties, whether
          express, implied, statutory or otherwise, with respect to the app,
          including all implied warranties of merchantability, fitness for a
          particular purpose, title and non-infringement, and warranties that
          may arise out of course of dealing, course of performance, usage or
          trade practice.
        </dd>
        <dd>
          Without limitation to the foregoing, Life by Petto provides no
          warranty or undertaking, and makes no representation of any kind that
          the app will meet your requirements, achieve any intended results, be
          compatible or work with any other software, , systems or services,
          operate without interruption, meet any performance or reliability
          standards or be error free or that any errors or defects can or will
          be corrected.
        </dd>
        <dd>
          Without limiting the foregoing, neither Life by Petto nor any Life by
          Petto's provider makes any representation or warranty of any kind,
          express or implied: (i) as to the operation or availability of the
          app, or the information, content, and materials or products included
          thereon; (ii) that the app will be uninterrupted or error-free; (iii)
          as to the accuracy, reliability, or currency of any information or
          content provided through the app; or (iv) that the app, its servers,
          the content, or e-mails sent from or on behalf of Life by Petto is
          free of viruses, scripts, trojan horses, worms, malware, timebombs or
          other harmful components. Some jurisdictions do not allow the
          exclusion of or limitations on implied warranties or the limitations
          on the applicable statutory rights of a consumer, so some or all of
          the above exclusions and limitations may not apply to you.
        </dd>
      </dl>
      <dl>
        <dt>Limitation of Liability</dt>
        <dd>
          Notwithstanding any damages that you might incur, the entire liability
          of Life by Petto and any of its suppliers under any provision of this
          Agreement and your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by you for the app.
        </dd>
        <dd>
          To the maximum extent permitted by applicable law, in no event shall
          Life by Petto or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, for loss of data or other
          information, for business interruption, for personal injury, for loss
          of privacy arising out of or in any way related to the use of or
          inability to use the app, third-party software and/or third-party
          hardware used with the app, or otherwise in connection with any
          provision of this Agreement), even if Life by Petto or any supplier
          has been advised of the possibility of such damages and even if the
          remedy fails of its essential purpose.
        </dd>
        <dd>
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </dd>
      </dl>
      <dl>
        <dt>Severability</dt>
        <dd>
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </dd>
        <dd>
          This Agreement, together with the Privacy Policy and any other legal
          notices published by Life by Petto on the Services, shall constitute
          the entire agreement between you and Life by Petto concerning the
          Services. If any provision of this Agreement is deemed invalid by a
          court of competent jurisdiction, the invalidity of such provision
          shall not affect the validity of the remaining provisions of this
          Agreement, which shall remain in full force and effect. No waiver of
          any term of this Agreement shall be deemed a further or continuing
          waiver of such term or any other term, and Life by Petto."’s" failure
          to assert any right or provision under this Agreement shall not
          constitute a waiver of such right or provision. You and Life by Petto
          agree that any cause of action arising out of or related to the
          services must commence within one (1) year after the cause of action
          accrues. Otherwise, such cause of action is permanently barred.{" "}
        </dd>
      </dl>
      <dl>
        <dt>Waiver</dt>
        <dd>
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Agreement shall not
          affect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute waiver of any subsequent breach. Failure to exercise, and
          no delay in exercising, on the part of either party, any right or any
          power under this Agreement shall operate as a waiver of that right or
          power. Nor shall any single or partial exercise of any right or power
          under this Agreement preclude further exercise of that or any other
          right granted herein. In the event of a conflict between this
          Agreement and any applicable purchase or other terms, the terms of
          this Agreement shall govern.
        </dd>
      </dl>
      <dl>
        <dt>Amendments to this Agreement</dt>
        <dd>
          Life by Petto reserves the right, at its sole discretion, to modify or
          replace this Agreement at any time. If a revision is material we will
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </dd>
        <dd>
          By continuing to access or use our app after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorised to use Life by
          Petto
        </dd>
      </dl>
      <dl>
        <dt>Entire Agreement</dt>
        <dd>
          The Agreement constitutes the entire agreement between you and Life by
          Petto regarding your use of the app and supersedes all prior and
          contemporaneous written or oral agreements between you and Life by
          Petto.
        </dd>
        <dd>
          You may be subject to additional terms and conditions that apply when
          you use or purchase other Life by Petto's services, which Life by
          Petto will provide to you at the time of such use or purchase.
        </dd>
      </dl>
      <dl>
        <dt>Updates to Our Terms</dt>
        <dd>
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Unless otherwise required by law, we will notify you (for
          example, through our Service) before we make changes to these Terms
          and give you an opportunity to review them before they go into effect.
          Then, if you continue to use the Service, you will be bound by the
          updated Terms. If you do not want to agree to these or any updated
          Terms, you can delete your account.
        </dd>
      </dl>
      <dl>
        <dt>Intellectual Property</dt>
        <dd>
          The app and its entire contents, features and functionality (including
          but not limited to all information, software, text, displays, images,
          video and audio, and the design, selection and arrangement thereof),
          are owned by Life by Petto, its licensors or other providers of such
          material and are protected by Singapore and international copyright,
          trademark, patent, trade secret and other intellectual property or
          proprietary rights laws. The material may not be copied, modified,
          reproduced, downloaded or distributed in any way, in whole or in part,
          without the express prior written permission of Life by Petto, unless
          and except as is expressly provided in these Terms & Conditions. Any
          unauthorised use of the material is prohibited.
        </dd>
      </dl>
      <dl>
        <dt>Agreement to Arbitrate</dt>
        <dd>
          This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A
          DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
          REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Life by Petto’s
          INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute,
          action, or other controversy between you and Life by Petto concerning
          the Services or this agreement, whether in contract, warranty, tort,
          statute, regulation, ordinance, or any other legal or equitable basis.
          “Dispute” will be given the broadest possible meaning allowable under
          law.
        </dd>
      </dl>
      <dl>
        <dt>Notice of Dispute</dt>
        <dd>
          In the event of a dispute, you or Life by Petto must give the other a
          Notice of Dispute, which is a written statement that sets forth the
          name, address, and contact information of the party giving it, the
          facts giving rise to the dispute, and the relief requested.
        </dd>
        <dd>
          You must send any Notice of Dispute via email to:
          info@lifebypetto.com. Life by Petto will send any Notice of Dispute to
          you by mail to your address if we have it, or otherwise to your email
          address. You and Life by Petto will attempt to resolve any dispute
          through informal negotiation within sixty (60) days from the date the
          Notice of Dispute is sent. After sixty (60) days, you or Life by Petto
          may commence arbitration.
        </dd>
      </dl>
      <dl>
        <dt>Binding Arbitration</dt>
        <dd>
          If you and Life by Petto don’t resolve any dispute by informal
          negotiation, any other effort to resolve the dispute will be conducted
          exclusively by binding arbitration as described in this section. You
          are giving up the right to litigate (or participate in as a party or
          class member) all disputes in court before a judge or jury. The
          dispute shall be settled by binding arbitration in accordance with the
          commercial arbitration rules of the American Arbitration Association.
          Either party may seek any interim or preliminary injunctive relief
          from any court of competent jurisdiction, as necessary to protect the
          party’s rights or property pending the completion of arbitration. Any
          and all legal, accounting, and other costs, fees, and expenses
          incurred by the prevailing party shall be borne by the non-prevailing
          party.
        </dd>
      </dl>
      <dl>
        <dt>Submissions and Privacy</dt>
        <dd>
          In the event that you submit or post any ideas, creative suggestions,
          designs, photographs, information, advertisements, data or proposals,
          including ideas for new or improved products, services, features,
          technologies or promotions, you expressly agree that such submissions
          will automatically be treated as non-confidential and non-proprietary
          and will become the sole property of Life by Petto without any
          compensation or credit to you whatsoever. Life by Petto and its
          affiliates shall have no obligations with respect to such submissions
          or posts and may use the ideas contained in such submissions or posts
          for any purposes in any medium in perpetuity, including, but not
          limited to, developing, manufacturing, and marketing products and
          services using such ideas.
        </dd>
      </dl>
      <dl>
        <dt>Promotions</dt>
        <dd>
          Life by Petto may, from time to time, include contests, promotions,
          sweepstakes, or other activities (“Promotions”) that require you to
          submit material or information concerning yourself. Please note that
          all Promotions may be governed by separate rules that may contain
          certain eligibility requirements, such as restrictions as to age and
          geographic location. You are responsible to read all Promotions rules
          to determine whether or not you are eligible to participate. If you
          enter any Promotion, you agree to abide by and to comply with all
          Promotions Rules.
        </dd>
        <dd>
          Additional terms and conditions may apply to purchases of goods or
          services on or through the Services, which terms and conditions are
          made a part of this Agreement by this reference.
        </dd>
      </dl>
      <dl>
        <dt>Typographical Errors</dt>
        <dd>
          In the event a product and/or service is listed at an incorrect price
          or with incorrect information due to typographical error, we shall
          have the right to refuse or cancel any orders placed for the product
          and/or service listed at the incorrect price. We shall have the right
          to refuse or cancel any such order whether or not the order has been
          confirmed and your credit card charged. If your credit card has
          already been charged for the purchase and your order is cancelled, we
          shall immediately issue a credit to your credit card account or other
          payment account in the amount of the charge.
        </dd>
      </dl>
      <dl>
        <dt>Miscellaneous</dt>
        <dd>
          If for any reason a court of competent jurisdiction finds any
          provision or portion of these Terms & Conditions to be unenforceable,
          the remainder of these Terms & Conditions will continue in full force
          and effect. Any waiver of any provision of these Terms & Conditions
          will be effective only if in writing and signed by an authorised
          representative of Life by Petto. Life by Petto will be entitled to
          injunctive or other equitable relief (without the obligations of
          posting any bond or surety) in the event of any breach or anticipatory
          breach by you. Life by Petto operates and controls the Life by Petto
          Service from its offices in Singapore and Philippines. The Service is
          not intended for distribution to or use by any person or entity in any
          jurisdiction or country where such distribution or use would be
          contrary to law or regulation. Accordingly, those persons who choose
          to access the Life by Petto Service from other locations do so on
          their own initiative and are solely responsible for compliance with
          local laws, if and to the extent local laws are applicable. These
          Terms & Conditions (which include and incorporate the Life by Petto
          Privacy Policy) contains the entire understanding, and supersedes all
          prior understandings, between you and Life by Petto concerning its
          subject matter, and cannot be changed or modified by you. The section
          headings used in this Agreement are for convenience only and will not
          be given any legal import.
        </dd>
      </dl>
      <dl>
        <dt>Disclaimer</dt>
        <dd>
          Life by Petto is not responsible for any content, code or any other
          imprecision.
        </dd>
        <dd>Life by Petto does not provide warranties or guarantees.</dd>
        <dd>
          In no event shall Life by Petto be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The Company reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </dd>
        <dd>
          The Life by Petto Service and its contents are provided "as is" and
          "as available" without any warranty or representations of any kind,
          whether express or implied. Life by Petto is a distributor and not a
          publisher of the content supplied by third parties; as such, Life by
          Petto exercises no editorial control over such content and makes no
          warranty or representation as to the accuracy, reliability or currency
          of any information, content, service or merchandise provided through
          or accessible via the Life by Petto Service. Without limiting the
          foregoing, Life by Petto specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          the Life by Petto Service or on sites that may appear as links on the
          Life by Petto Service, or in the products provided as a part of, or
          otherwise in connection with, the Life by Petto Service, including
          without limitation any warranties of merchantability, fitness for a
          particular purpose or non-infringement of third party rights. No oral
          advice or written information given by Life by Petto or any of its
          affiliates, employees, officers, directors, agents, or the like will
          create a warranty. Price and availability information is subject to
          change without notice. Without limiting the foregoing, Life by Petto
          does not warrant that the Life by Petto Service will be uninterrupted,
          uncorrupted, timely, or error-free.
        </dd>
      </dl>
      <dl>
        <dt>Contact Us</dt>
        <dd>Don't hesitate to contact us if you have any questions.</dd>
        <dd>
          Via Email:{" "}
          <a href="mailto:info@lifebypetto.com">info@lifebypetto.com</a>
        </dd>

        <dd>
          Via this Link:{" "}
          <a href="https://www.lifebypetto.com/contact-us">
            {" "}
            lifebypetto.com/contact-us{" "}
          </a>
        </dd>
      </dl>
    </div>
  );
}

export default TermsConditions;
