import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./GroomingServices.css";
function GroomingServices() {
  return (
    <div>
      <Container>
        <Row className="grooming">
          <Col md={8} className="grooming-header">
            <h1>Grooming Services</h1>
            <i
              style={{ color: "white", textAlign: "center", cursor: "pointer" }}
            >
            Coming Soon
            </i>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default GroomingServices;
