import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import Footer from "../Components/Footer/Footer";
import SiteFooter from "../Components/SiteFooter/SiteFooter";

function PrivacyPage() {
  return (
    <div>
      <Navbar />
      <PrivacyPolicy />
      <Footer />
      <SiteFooter />
    </div>
  );
}

export default PrivacyPage;
