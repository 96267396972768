import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutImage from "../../assets/images/about.svg";
import "./About.css";

function About() {
  return (
    <div>
      <Container>
        <Row className="about-section">
          <Col md={6} xs={12} className="about-image">
            <img src={aboutImage} alt="About us" />
          </Col>
          <Col
            md={6}
            xs={12}
            style={{
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <h4>How it all started.</h4>
            <p>Life by Petto’s story began with a special needs cat.</p>
            <p>
              Our co-founder’s kitten was diagnosed with a terminal disease and
              was sent home for palliative care with just months to live.
              Fortunately, the right support and resources enabled her to make a
              full recovery.
            </p>
            <p>
              Many pet owners are not as lucky when it comes to supporting their
              pets’ unexpected illnesses. This was why we created Life by Petto
            </p>
            <p>
              Life by Petto enables access to health and wellness services from
              partner veterinarians and vet clinics, and retail services that
              make life better for our pets. We are pet parents ourselves so our
              entire approach to this platform is with our needs in mind.
            </p>
            <span
              style={{
                backgroundColor: "#FFF883",
                width: "280px",
                marginBottom: "10px ",
              }}
            >
              Life by Petto, a pet’s life well-lived.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
