import React from "react";
import "./HomeBanner.css";
import { RiArrowDropRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import webHeader from '../../assets/images/webHeader.png'
function HomeBanner() {
  return (
    <div className="home-banner">
      <div className="banner-image">
      <img src={webHeader} alt="" srcset="" />
      </div>
      <div className="banner-data">
        <h3>
          {" "}
          Welcome to
          <br></br>
          The Pawmily!
        </h3>
        <button>
        <Link to="/plans">
        Check out our plans here <RiArrowDropRightLine fontSize="20px" />
        </Link>
        </button>
      </div>
    </div>
  );
}

export default HomeBanner;
