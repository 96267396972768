import React, { useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import HomeBanner from "../Components/HomeBanner/HomeBanner";
import GuideUs from "../Components/GuideUs/GuideUs";
import Review from "../Components/Reviews/Review";
import PartnerBanner from "../Components/Partner-banner/PartnerBanner";
import Footer from "../Components/Footer/Footer";
import Featured from "../Components/Featured/Featured";
import ContactModal from "../Modal/ContactModal";
import SiteFooter from "../Components/SiteFooter/SiteFooter";

function LandingPage() {
  return (
    <div>
      <Navbar />
      <HomeBanner />
      <GuideUs />
      <Review />
      <PartnerBanner />
      <Featured />
      <Footer />
      <SiteFooter />
    </div>
  );
}

export default LandingPage;
