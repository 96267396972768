import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import About from '../Components/About/About'
import SiteFooter from '../Components/SiteFooter/SiteFooter'

function AboutPage() {
  return (
    <div>
      <Navbar/>
      <About/>
<Footer/>
<SiteFooter/>
    </div>
  )
}

export default AboutPage
