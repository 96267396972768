import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./Pages/LandingPage";
import PartnerPage from "./Pages/PartnerPage";
import PlansPage from "./Pages/PlansPage";
import SupportPage from "./Pages/SupportPage";
import AboutPage from "./Pages/AboutPage";
import TermsPage from "./Pages/TermsPage";
import PrivacyPage from "./Pages/PrivacyPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/partners" element={<PartnerPage />} />
        <Route path="/plans" element={<PlansPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path='/termsandconditions' element={<TermsPage/>}/>
        <Route path='/privacypolicy' element={<PrivacyPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
