import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import TermsConditions from "../Components/Terms&Conditions/Terms&Conditions";
import SiteFooter from "../Components/SiteFooter/SiteFooter";

function TermsPage() {
  return (
    <div>
      <Navbar />
      <TermsConditions />
      <Footer />
      <SiteFooter />
    </div>
  );
}

export default TermsPage;
