import React from "react";
import "./ForHumans.css";
import { Col, Container, Row } from "react-bootstrap";

function ForHumans() {
  return (
    <div>
      <Container>
        <Row className="forhumans">
          <Col md={6}></Col>
          <Col md={6} className="humans-header">
            <h1>For Humans</h1>
            <i style={{ color: "white", cursor: "pointer" }}>Coming Soon</i>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForHumans;
