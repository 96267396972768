import React from "react";
import "./Privacy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p style={{ marginBottom: "10px" }}> Updated at 2024-01-01</p>
      <dl style={{ marginTop: "20px" }}>
        <dd>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </dd>
        <dd>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </dd>
      </dl>
      <dl>
        <b>Interpretation and Definitions</b>
        <dt>Interpretation</dt>
        <dd>
          The words of which the initial letter is capitalised have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </dd>
      </dl>
      <dl>
        <dt>Definitions</dt>
        <dd>For the purposes of this Privacy Policy:</dd>
        <ul>
          <li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li>
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where "control" means ownership
            of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing
            authority.
          </li>
          <li>
            Application means the software program provided by the Company
            downloaded by You on any electronic device, named Life by Petto
          </li>
          <li>
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to Life by Petto Pte Ltd, 410 North Bridge
            Road
          </li>
          <li>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li>Country refers to: Singapore</li>
          <li>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </li>
          <li>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
          <li>Service refers to the Application or the Website or both.</li>
          <li>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analysing how the
            Service is used.
          </li>
          <li>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </li>
          <li>
            Website refers to Life by Petto, accessible from lifebypetto.com
          </li>
          <li>
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </li>
        </ul>
      </dl>
      <dl>
        <b>Collecting and Using Your Personal Data</b>
        <dt>Types of Data Collected</dt>
      </dl>
      <dl>
        <dt>Personal Data</dt>
        <dd>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
          <ul style={{ listStyle: "none" }}>
            <li>Email address</li>
            <li> First name and last name</li>
            <li>Phone number</li>
            <li> Address, State, Province, ZIP/Postal code, City</li>
          </ul>
        </dd>
      </dl>

      <dl>
        <dt>Usage Data</dt>
        <dd>Usage Data is collected automatically when using the Service.</dd>
        <dd>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </dd>
        <dd>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </dd>
        <dd>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </dd>
      </dl>
      <dl>
        <dt>Information Collected while Using the Application</dt>
        <dd>
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </dd>
        <dd>Information regarding your location</dd>
        <dd>
          Pictures and other information from your Device's camera and photo
          library
        </dd>
        <dd>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </dd>
        <dd>
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </dd>
      </dl>
      <dl>
        <dt>Tracking Technologies and Cookies</dt>
        <dd>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyse Our Service. The technologies We use may
          include:
        </dd>
        <dd>
          Cookies or Browser Cookies. A cookie is a small file placed on Your
          Device. You can instruct Your browser to refuse all Cookies or to
          indicate when a Cookie is being sent. However, if You do not accept
          Cookies, You may not be able to use some parts of our Service. Unless
          you have adjusted Your browser setting so that it will refuse Cookies,
          our Service may use Cookies.
        </dd>
        <dd>
          Flash Cookies. Certain features of our Service may use local stored
          objects (or Flash Cookies) to collect and store information about Your
          preferences or Your activity on our Service. Flash Cookies are not
          managed by the same browser settings as those used for Browser
          Cookies. For more information on how You can delete Flash Cookies,
          please read "Where can I change the settings for disabling, or
          deleting local shared objects?" available at{" "}
          <a href=" https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
            https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
          </a>
        </dd>
        <dd>
          Web Beacons. Certain sections of our Service and our emails may
          contain small electronic files known as web beacons (also referred to
          as clear gifs, pixel tags, and single-pixel gifs) that permit the
          Company, for example, to count users who have visited those pages or
          opened an email and for other related website statistics (for example,
          recording the popularity of a certain section and verifying system and
          server integrity). Cookies can be "Persistent" or "Session" Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser. You can learn more about cookies here: All
          About Cookies by TermsFeed.
        </dd>
        <dd>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </dd>
        <dd>Necessary / Essential Cookies</dd>
        <dd>Type: Session Cookies</dd>
        <dd>Administered by: Us</dd>
        <dd>
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </dd>
        <dd>Cookies Policy / Notice Acceptance Cookies</dd>
        <dd>Type: Persistent Cookies</dd>
        <dd>Administered by: Us</dd>
        <dd>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </dd>
        <dd>Functionality Cookies</dd>
        <dd>Type: Persistent Cookies</dd>
        <dd>Administered by: Us</dd>
        <dd>
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </dd>
        <dd>Tracking and Performance Cookies</dd>
        <dd>Type: Persistent Cookies</dd>
        <dd>Administered by: Third-Parties</dd>
        <dd>
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new pages, features or new functionality of the Website to see
          how our users react to them.
        </dd>
        <dd>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </dd>
      </dl>
      <dl>
        <dt>Use of Your Personal Data</dt>
        <dd>The Company may use Personal Data for the following purposes:</dd>
        <dd>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </dd>
        <dd>
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user. For the performance of a contract: the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </dd>
        <dd>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </dd>
        <dd>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </dd>
        <dd>
          To manage Your requests: To attend and manage Your requests to Us.
        </dd>
        <dd>
          To deliver targeted advertising to You: We may use Your information to
          develop and display content and advertising (and work with third-party
          vendors who do so) tailored to Your interests and/or location and to
          measure its effectiveness
        </dd>
        <dd>
          For business transfers: We may use Your information to evaluate or
          conduct a merger, divestiture, restructuring, reorganisation,
          dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which Personal Data held by Us about our
          Service users is among the assets transferred.
        </dd>
        <dd>
          For other purposes: We may use Your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns and to evaluate and improve
          our Service, products, services, marketing and your experience
        </dd>
        <dd>
          We may share Your personal information in the following situations:
        </dd>
        <dd>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          advertise on third party websites to You after You visited our
          Service, for payment processing, to contact You.
        </dd>
        <dd>
          For business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </dd>
        <dd>
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honour this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </dd>
        <dd>
          With business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </dd>
        <dd>
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </dd>
        <dd>
          With Your consent: We may disclose Your personal information for any
          other purpose with Your consent.
        </dd>
      </dl>
      <dl>
        <dt>Retention of Your Personal Data</dt>
        <dd>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </dd>
        <dd>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </dd>
      </dl>
      <dl>
        <dt>Transfer of Your Personal Data</dt>
        <dd>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ from those from Your
          jurisdiction
        </dd>
        <dd>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </dd>
        <dd>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organisation or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </dd>
      </dl>
      <dl>
        <dt>Disclosure of Your Personal Data</dt>
        <dd>
          <p>Business Transactions</p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </dd>
        <dd>
          <p>Law enforcement</p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </dd>
        <dd>
          <p>Other legal requirements</p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
          <ul style={{ listStyle: "disc" }}>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
        </dd>
      </dl>
      <dl>
        <dt>Security of Your Personal Data</dt>
        <dd>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </dd>
      </dl>
      <dl>
        <dt>Detailed Information on the Processing of Your Personal Data</dt>
        <dd>
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </dd>
      </dl>
      <dl>
        <dt>Analytics</dt>
        <dd>
          We may use third-party Service providers to monitor and analyse the
          use of our Service.
        </dd>
      </dl>
      <dl>
        <dt>Google Analytics</dt>
        <dd>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
        </dd>
        <dd>
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js and dc.js) from sharing information with Google Analytics
          about visits activity.
        </dd>
        <dd>
          You may opt-out of certain Google Analytics features through your
          mobile device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy Policy:{" "}
          <a href="https://policies.google.com/privacy">
            {" "}
            https://policies.google.com/privacy{" "}
          </a>
        </dd>
        <dd>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
        </dd>
      </dl>
      <dl>
        <dt>Email Marketing</dt>
        <dd>
          We may use Your Personal Data to contact You with newsletters,
          marketing or promotional materials and other information that may be
          of interest to You. You may opt-out of receiving any, or all, of these
          communications from Us by following the unsubscribe link or
          instructions provided in any email We send or by contacting Us.
        </dd>
        <dd>
          We may use Email Marketing Service Providers to manage and send emails
          to You.
        </dd>
      </dl>
      <dl>
        <dt>Twilio SendGrid</dt>
        <dd>
          Their Privacy Policy can be viewed at{" "}
          <a href="https://sendgrid.com/policies/security/">
            https://sendgrid.com/policies/security/
          </a>
        </dd>
      </dl>
      <dl>
        <dt>Payments</dt>
        <dd>
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors).
        </dd>
        <dd>
          We will not store or collect Your payment card details. That
          information is provided directly to Our third-party payment processors
          whose use of Your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </dd>
        <dd>
          <p>Stripe</p>
          Their Privacy Policy can be viewed at :{" "}
          <a href="https://stripe.com/us/privacy">
            https://stripe.com/us/privacy
          </a>
        </dd>

        <dd>
          <p>Paynamics</p>
          Their Privacy Policy can be viewed at :{" "}
          <a href="https://www.paynamics.com/privacy-policy">
            https://www.paynamics.com/privacy-policy
          </a>
        </dd>
        <dd>
          <p>GCash</p>
          Their Privacy Policy can be viewed at :{" "}
          <a href="https://www.gcash.com/privacy-notice/v1.0/">
            https://www.gcash.com/privacy-notice/v1.0/
          </a>
        </dd>
        <dd>
          <p>Maya</p>
          Their Privacy Policy can be viewed at :{" "}
          <a href="https://www.maya.ph/privacy">https://www.maya.ph/privacy</a>
        </dd>
      </dl>
      <dl>
        <dt>Behavioral Remarketing</dt>
        <dd>
          The Company uses remarketing services to advertise to You after You
          accessed or visited our Service. We and Our third-party vendors use
          cookies and non-cookie technologies to help Us recognize Your Device
          and understand how You use our Service so that We can improve our
          Service to reflect Your interests and serve You advertisements that
          are likely to be of more interest to You.
        </dd>
        <dd>
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies and to enable Us to:
          <ol style={{ listStyle: "disc" }}>
            <li>
              Measure and analyse traffic and browsing activity on Our Service
            </li>
            <li>
              Show advertisements for our products and/or services to You on
              third-party websites or apps{" "}
            </li>
            <li>
              Measure and analyse the performance of Our advertising campaigns
            </li>
            <li>
              Some of these third-party vendors may use non-cookie technologies
              that may not be impacted by browser settings that block cookies.
              Your browser may not permit You to block such technologies. You
              can use the following third-party tools to decline the collection
              and use of information for the purpose of serving You
              interest-based advertising:
            </li>
          </ol>
        </dd>
        <dd>
          The NAI's opt-out platform:{" "}
          <a href="http://www.networkadvertising.org/choices/">
            http://www.networkadvertising.org/choices/
          </a>
        </dd>
        <dd>
          The EDAA's opt-out platform:{" "}
          <a href="http://www.youronlinechoices.com/">
            http://www.youronlinechoices.com/
          </a>
        </dd>
        <dd>
          The DAA's opt-out platform:{" "}
          <a href="http://optout.aboutads.info/?c=2&lang=EN">
            http://optout.aboutads.info/?c=2&lang=EN
          </a>
        </dd>
        <dd>
          You may opt-out of all personalized advertising by enabling privacy
          features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
          Out of Ads Personalization (Android). See Your mobile device Help
          system for more information.
        </dd>
        <dd>
          We may share information, such as hashed email addresses (if
          available) or other online identifiers collected on Our Service with
          these third-party vendors. This allows Our third-party vendors to
          recognize and deliver You ads across devices and browsers. To read
          more about the technologies used by these third-party vendors and
          their cross-device capabilities please refer to the Privacy Policy of
          each vendor listed below
        </dd>
        <dd>he third-party vendors We use are:</dd>
        <dd>Google Ads (AdWords)</dd>
        <dd>
          Google Ads (AdWords) remarketing service is provided by Google Inc.
        </dd>
        <dd>
          You can opt-out of Google Analytics for Display Advertising and
          customise the Google Display Network ads by visiting the Google Ads
          Settings page:
          <a href="http://www.google.com/settings/ads">
            http://www.google.com/settings/ads
          </a>
        </dd>
        <dd>
          Google also recommends installing the Google Analytics Opt-out Browser
          Add-on -{" "}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          - for your web browser. Google Analytics Opt-out Browser Add-on
          provides visitors with the ability to prevent their data from being
          collected and used by Google Analytics.
        </dd>
        <dd>
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
        </dd>

        <dd>
          <p>Facebook</p>
          Facebook remarketing service is provided by Facebook Inc.
        </dd>
        <dd>
          You can learn more about interest-based advertising from Facebook by
          visiting this page:{" "}
          <a href="https://www.facebook.com/help/516147308587266">
            https://www.facebook.com/help/516147308587266
          </a>
        </dd>
        <dd>
          To opt-out from Facebook's interest-based ads, follow these
          instructions from Facebook:{" "}
          <a href="https://www.facebook.com/help/568137493302217">
            https://www.facebook.com/help/568137493302217
          </a>
        </dd>
        <dd>
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA{" "}
          <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/,
          </a>{" "}
          the Digital Advertising Alliance of Canada in Canada
          <a href="http://youradchoices.ca/"> http://youradchoices.ca/ </a>
          or the European Interactive Digital Advertising Alliance in Europe{" "}
          <a href="http://www.youronlinechoices.eu/">
            http://www.youronlinechoices.eu/,
          </a>
          or opt-out using your mobile device settings
        </dd>
        <dd>
          For more information on the privacy practices of Facebook, please
          visit Facebook's Data Policy:
          <a href="https://www.facebook.com/privacy/explanation">
            https://www.facebook.com/privacy/explanation
          </a>
        </dd>
        <dd>
          <p>Children's Privacy</p>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </dd>
        <dd>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information
        </dd>
        <dd>
          <p>Links to Other Websites </p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </dd>
        <dd>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </dd>
        <dd>
          <p>Changes to this Privacy Policy</p> We may update Our Privacy Policy
          from time to time. We will notify You of any changes by posting the
          new Privacy Policy on this page.
        </dd>
        <dd>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </dd>
        <dd>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </dd>
      </dl>
      <dl>
        <dt>Contact Us</dt>
        <dd>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </dd>
        <dd>
          Via Email:{" "}
          <a href="mailto:info@lifebypetto.com">info@lifebypetto.com</a>
        </dd>

        <dd>
          Via this Link:{" "}
          <a href="https://www.lifebypetto.com/contact-us">
            {" "}
            lifebypetto.com/contact-us{" "}
          </a>
        </dd>
      </dl>
    </div>
  );
}

export default PrivacyPolicy;
