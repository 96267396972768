import React, { useState, useEffect } from "react";
import "./PartnerBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import vetinaryclinics from "../../assets/images/vet-logo.png";
import groomingServices from "../../assets/images/grooming-logo.png";
import petSupplies from "../../assets/images/pet-supplies-logo.png";
import animalWelfare from "../../assets/images/animal-welfare-logo.png";
import humans from "../../assets/images/for-humans.png";
import { RiArrowDropRightLine } from "react-icons/ri";
import ContactModal from "../../Modal/ContactModal";
function PartnerBanner() {
  const partners = [
    { id: 1, image: vetinaryclinics, title: "Vetinary Clinics" },
    { id: 2, image: groomingServices, title: "Grooming Services" },
    { id: 3, image: petSupplies, title: "Pet Supplies" },
    { id: 4, image: animalWelfare, title: "Animal Welfare Groups" },
    { id: 5, image: humans, title: "For Humans" },
  ];
  const [show, setShow] = useState(false);

  const handleClick = () => {
    console.log(show);
    setShow(!show);
  };

  return (
    <div>
      <div className="partner-banner">
        <div className="partner-detials">
          <h2
            style={{
              color: "white",
              fontWeight: 800,
              width: "100%",
              position: "relative",
              left: "-50px",
            }}
          >
            PARTNERS
          </h2>
          <Row className="partner-data">
            {partners.map((partner) => (
              <Col
                xs={6}
                sm={6}
                md={4}
                key={partner.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "180px",
                }}
              >
                <div className="partners">
                  <img src={partner.image} alt={partner.title} />
                </div>
                <p>{partner.title}</p>
              </Col>
            ))}
          </Row>

          <button
            onClick={handleClick}
            style={{ position: "relative", left: "-50px" }}
          >
            Sign up as a Partner{" "}
            <RiArrowDropRightLine style={{ fontSize: "30px" }} />
          </button>
        </div>

        {show && <ContactModal show={show} setShow={setShow} />}
      </div>

      <div className="partner-res-banner">
        <div className="partenr-res-head">
          <h1> Partners</h1>
        </div>
        <Container className="partenr-res-logo">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {partners.map((partner) => (
              <Col xs={4}>
                <img src={partner.image} alt="logos" />
                <p>{partner.title}</p>
              </Col>
            ))}
          </Row>
          <button onClick={handleClick}>
            {" "}
            Sign up as a partner{" "}
            <RiArrowDropRightLine style={{ fontSize: "22px" }} />
          </button>
          {show && <ContactModal show={show} setShow={setShow} />}
        </Container>
      </div>
    </div>
  );
}

export default PartnerBanner;
