import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import accidents from "../../assets/images/accidents.png";
import vaccines from "../../assets/images/vaccines.png";
import diagnostics from "../../assets/images/diagnostics.png";
import spay from "../../assets/images/spay.png";
import surgeries from "../../assets/images/surgeries.png";
import commonIllness from "../../assets/images/CommonIllnesses.png";

import "./Plans.css";
function Plans() {
  return (
    <div className="plan-page">
      <Container>
        <div className="plan-title">
          <h4>One plan. Made better and more affordable. PhP 990</h4>
          <p>
            We created a plan that covers the basics of our pet’s health and
            wellness needs – diagnostics, vaccines, and accidents – services
            that we know are important to pet parents and we’ll happily cover.
          </p>
        </div>
        <Row className="plans">
          <Col md={8} className="plan-detials">
            <div className="plan-head">
              <h3>Basic Plan 990</h3>
              <p>Health Plan valued at PHP 100,000</p>
            </div>
            <div className="plan-services">
              <div className="plan-service">
                <img src={diagnostics} alt="" srcset="" />
                <p>Diagnostics</p>
              </div>
              <div className="plan-service">
                <img src={vaccines} alt="" srcset="" />
                <p>Vaccines</p>
              </div>
              <div className="plan-service">
                <img src={accidents} alt="" srcset="" />
                <p>Accidents</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="div" style={{marginTop:"60px"}}>
          <div className="plan-title">
            <h4>Top up plan. Simplified. PhP 1499</h4>
            <p>
              Our basic plan covers all the essentials. The new top up plan
              includes spay/neuter procedures, surgeries, and common illnesses.
            </p>
          </div>
          <Row className="plans add-on">
            <Col md={8} className="plan-detials">
              <div className="plan-head">
                <h3>Plan +1499</h3>
                <p>Additional Coverage of up to 20% of procedure cost </p>
              </div>
              <div className="plan-services">
                <div className="plan-service">
                  <img src={spay} alt="" srcset="" />
                  <p>Spay and Neutral</p>
                </div>
                <div className="plan-service">
                  <img src={commonIllness} alt="" srcset="" />
                  <p>Common Illness</p>
                </div>
                <div className="plan-service">
                  <img src={surgeries} alt="" srcset="" />
                  <p>Surgeries</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Plans;
