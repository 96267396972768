import React, { useState } from "react";
import { Container } from "react-bootstrap";
import arrowUp from "../../assets/images/arrow-up.png";
import arrowDown from "../../assets/images/arrow-down.png";
import "./Support.css";

const questionsAndAnswers = [
  {
    question:
      "Is there a limit to the age of the pets enrolling for a Life by Petto membership?",
    answer:
      "We require your fur baby to be at least 3 months old at the time of enrolment. This ensures that they are properly weaned and ready to be part of your family.",
  },
  {
    question: "When can I start using the service?",
    answer:
      "You may start enjoying Life by Petto services with our accredited vets as soon as we validate your pet's health certificate (which you can submit through the app) within 14 days of paying for your membership fee. Otherwise, there is a 90 day waiting period for the service.",
  },
  {
    question: "What is a vet health certificate?",
    answer:
      "A health/wellness certificate from your vet is a document that you get from your pet's vet that indicates the current health state of your pet. You will need to submit this within 14 days of paying for a Life by Petto account in order to be able to use our services right away.",
  },
  {
    question: "I missed a payment, can I still use the service?",
    answer:
      "When you miss a payment, you won’t be able to use the service until your outstanding dues are settled. To avoid missing your payments, you may also opt to avail of an annual plan (which also gets you one month free!).",
  },
  {
    question: "What services are not included in my Life by Petto membership?",
    answer:
      "Pre-existing conditions or any procedures/treatments that are breed-related are not covered. For the full list of inclusions and exclusions, please click here. (link out to PDF)",
  },
];

function Support() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleClick = (index) => {
    setOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div>
      <Container>
        <div className="support-section">
          <div className="support-header">
            <h3>Welcome to Life by Petto help Centre!</h3>
            <p>Choose the kind of help you need:</p>
          </div>
          <div className="support-body">
            <h4>Frequently Asked Questions:</h4>
            {questionsAndAnswers.map((qa, index) => (
              <div
                className="questions"
                style={{ textAlign: "left", display: "flex", gap: "10px" }}
                key={index}
              >
                <div className="question-icon">
                  <img
                    src={openIndexes.includes(index) ? arrowUp : arrowDown}
                    alt="toggle arrow"
                    onClick={() => handleClick(index)}
                  />
                </div>
                <div className="question-answer">
                  <div className="question">{qa.question}</div>
                  {openIndexes.includes(index) && (
                    <div className="answer">{qa.answer}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Support;
